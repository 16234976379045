import revive_payload_client_4sVQNw7RlN from "/home/forge/www.birlikcati.com.tr/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/home/forge/www.birlikcati.com.tr/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/home/forge/www.birlikcati.com.tr/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_MwZUzHrRNP from "/home/forge/www.birlikcati.com.tr/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_yVLowv6hDl from "/home/forge/www.birlikcati.com.tr/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/home/forge/www.birlikcati.com.tr/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/home/forge/www.birlikcati.com.tr/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/home/forge/www.birlikcati.com.tr/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/home/forge/www.birlikcati.com.tr/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/home/forge/www.birlikcati.com.tr/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/home/forge/www.birlikcati.com.tr/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_UYiXMU8ZyN from "/home/forge/www.birlikcati.com.tr/node_modules/nuxt-gtag/dist/runtime/plugin.client.js";
import plugin_I4dbrL2rYz from "/home/forge/www.birlikcati.com.tr/node_modules/@sidebase/nuxt-auth/dist/runtime/plugin.js";
import plugin_ghbUAjaD3n from "/home/forge/www.birlikcati.com.tr/node_modules/@nuxtjs/device/dist/runtime/plugin.js";
import switch_locale_path_ssr_5csfIgkrBP from "/home/forge/www.birlikcati.com.tr/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_sq1MuCrqbC from "/home/forge/www.birlikcati.com.tr/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import Vue3Marquee_client_r0z0zv1iLW from "/home/forge/www.birlikcati.com.tr/plugins/Vue3Marquee.client.ts";
import event_PwyMjymHGU from "/home/forge/www.birlikcati.com.tr/plugins/event.js";
import facebookPixel_client_VURhaxznpp from "/home/forge/www.birlikcati.com.tr/plugins/facebookPixel.client.js";
import googleTagManager_client_thuFVWPgPl from "/home/forge/www.birlikcati.com.tr/plugins/googleTagManager.client.js";
import hotjarPlugin_client_5R0lxpWqSI from "/home/forge/www.birlikcati.com.tr/plugins/hotjarPlugin.client.js";
import maska_r8BHBbSmHt from "/home/forge/www.birlikcati.com.tr/plugins/maska.js";
import showToast_client_i3Ts44oG79 from "/home/forge/www.birlikcati.com.tr/plugins/showToast.client.js";
import tooltip_client_JadcwBDZHC from "/home/forge/www.birlikcati.com.tr/plugins/tooltip.client.js";
import vue_gtag_client_fbnXi0Aqdd from "/home/forge/www.birlikcati.com.tr/plugins/vue-gtag.client.js";
import vueTelInput_client_B5wmek9POZ from "/home/forge/www.birlikcati.com.tr/plugins/vueTelInput.client.js";
import yandexMetricaPlugin_client_e7naLyjI7X from "/home/forge/www.birlikcati.com.tr/plugins/yandexMetricaPlugin.client.js";
import zendeskChatPlugin_client_KoBjt1O2w1 from "/home/forge/www.birlikcati.com.tr/plugins/zendeskChatPlugin.client.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_MwZUzHrRNP,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_client_UYiXMU8ZyN,
  plugin_I4dbrL2rYz,
  plugin_ghbUAjaD3n,
  switch_locale_path_ssr_5csfIgkrBP,
  i18n_sq1MuCrqbC,
  Vue3Marquee_client_r0z0zv1iLW,
  event_PwyMjymHGU,
  facebookPixel_client_VURhaxznpp,
  googleTagManager_client_thuFVWPgPl,
  hotjarPlugin_client_5R0lxpWqSI,
  maska_r8BHBbSmHt,
  showToast_client_i3Ts44oG79,
  tooltip_client_JadcwBDZHC,
  vue_gtag_client_fbnXi0Aqdd,
  vueTelInput_client_B5wmek9POZ,
  yandexMetricaPlugin_client_e7naLyjI7X,
  zendeskChatPlugin_client_KoBjt1O2w1
]