import { default as _91slug_93zLi4Sug5roMeta } from "/home/forge/www.birlikcati.com.tr/pages/[slug].vue?macro=true";
import { default as addressGUAG70bg9rMeta } from "/home/forge/www.birlikcati.com.tr/pages/account/address.vue?macro=true";
import { default as _91id_930UTZaQFgonMeta } from "/home/forge/www.birlikcati.com.tr/pages/account/orders/[id].vue?macro=true";
import { default as _91id_93Kz9f6SOF7HMeta } from "/home/forge/www.birlikcati.com.tr/pages/account/orders/cancel/[id].vue?macro=true";
import { default as indexemmv7yO73hMeta } from "/home/forge/www.birlikcati.com.tr/pages/account/orders/index.vue?macro=true";
import { default as reviewikpf9Hu4b4Meta } from "/home/forge/www.birlikcati.com.tr/pages/account/review.vue?macro=true";
import { default as _91id_93MIpt5Nkr4IMeta } from "/home/forge/www.birlikcati.com.tr/pages/account/statement/detail/[id].vue?macro=true";
import { default as indexHwimpcAXL0Meta } from "/home/forge/www.birlikcati.com.tr/pages/account/statement/index.vue?macro=true";
import { default as user1dvsqPX6JBMeta } from "/home/forge/www.birlikcati.com.tr/pages/account/user.vue?macro=true";
import { default as wishlistc9smznb6t5Meta } from "/home/forge/www.birlikcati.com.tr/pages/account/wishlist.vue?macro=true";
import { default as cartoFCiD7mk8zMeta } from "/home/forge/www.birlikcati.com.tr/pages/cart.vue?macro=true";
import { default as indexuA2pqLtfjdMeta } from "/home/forge/www.birlikcati.com.tr/pages/checkout/index.vue?macro=true";
import { default as paymentrZ4lNH4HNEMeta } from "/home/forge/www.birlikcati.com.tr/pages/checkout/payment.vue?macro=true";
import { default as summary_46clientBoWvXhZBlRMeta } from "/home/forge/www.birlikcati.com.tr/pages/checkout/summary.client.vue?macro=true";

let _createClientPage
async function createClientPage(loader) {
  _createClientPage ||= await import("/home/forge/www.birlikcati.com.tr/node_modules/nuxt/dist/components/runtime/client-component").then(r => r.createClientPage)
  return _createClientPage(loader);
}
import { default as requestl9iZdMTPdKMeta } from "/home/forge/www.birlikcati.com.tr/pages/customer/request.vue?macro=true";
import { default as successdSjLt97wSOMeta } from "/home/forge/www.birlikcati.com.tr/pages/customer/success.vue?macro=true";
import { default as iletisim90mhnWkLzaMeta } from "/home/forge/www.birlikcati.com.tr/pages/iletisim.vue?macro=true";
import { default as indexrAxK1zYQc0Meta } from "/home/forge/www.birlikcati.com.tr/pages/index.vue?macro=true";
import { default as loginWkZxO6fqg6Meta } from "/home/forge/www.birlikcati.com.tr/pages/login.vue?macro=true";
import { default as offeru9fWw9MAUVMeta } from "/home/forge/www.birlikcati.com.tr/pages/offer.vue?macro=true";
import { default as statusZXp8nSe3w4Meta } from "/home/forge/www.birlikcati.com.tr/pages/order/status.vue?macro=true";
import { default as trackv5YJUApMztMeta } from "/home/forge/www.birlikcati.com.tr/pages/order/track.vue?macro=true";
import { default as forgotmiWz4fcTugMeta } from "/home/forge/www.birlikcati.com.tr/pages/password/forgot.vue?macro=true";
import { default as resetMlDaKn1VLAMeta } from "/home/forge/www.birlikcati.com.tr/pages/password/reset.vue?macro=true";
import { default as payment_45notificationT8wZXBu341Meta } from "/home/forge/www.birlikcati.com.tr/pages/payment-notification.vue?macro=true";
import { default as summaryCRESfE51ZlMeta } from "/home/forge/www.birlikcati.com.tr/pages/payment/summary.vue?macro=true";
import { default as registerTvU9Wkdp4DMeta } from "/home/forge/www.birlikcati.com.tr/pages/register.vue?macro=true";
import { default as searchknXsk20peWMeta } from "/home/forge/www.birlikcati.com.tr/pages/search.vue?macro=true";
import { default as locationsQcNsWD4a1uMeta } from "/home/forge/www.birlikcati.com.tr/modules/locationsPage/pages/locations.vue?macro=true";
import { default as component_45stubMEdeFjB9DOMeta } from "/home/forge/www.birlikcati.com.tr/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubMEdeFjB9DO } from "/home/forge/www.birlikcati.com.tr/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "slug___tr",
    path: "/:slug()",
    meta: _91slug_93zLi4Sug5roMeta || {},
    component: () => import("/home/forge/www.birlikcati.com.tr/pages/[slug].vue")
  },
  {
    name: "account-address___tr",
    path: "/account/address",
    meta: addressGUAG70bg9rMeta || {},
    component: () => import("/home/forge/www.birlikcati.com.tr/pages/account/address.vue")
  },
  {
    name: "account-orders-id___tr",
    path: "/account/orders/:id()",
    meta: _91id_930UTZaQFgonMeta || {},
    component: () => import("/home/forge/www.birlikcati.com.tr/pages/account/orders/[id].vue")
  },
  {
    name: "account-orders-cancel-id___tr",
    path: "/account/orders/cancel/:id()",
    meta: _91id_93Kz9f6SOF7HMeta || {},
    component: () => import("/home/forge/www.birlikcati.com.tr/pages/account/orders/cancel/[id].vue")
  },
  {
    name: "account-orders___tr",
    path: "/account/orders",
    meta: indexemmv7yO73hMeta || {},
    component: () => import("/home/forge/www.birlikcati.com.tr/pages/account/orders/index.vue")
  },
  {
    name: "account-review___tr",
    path: "/account/review",
    meta: reviewikpf9Hu4b4Meta || {},
    component: () => import("/home/forge/www.birlikcati.com.tr/pages/account/review.vue")
  },
  {
    name: "account-statement-detail-id___tr",
    path: "/account/statement/detail/:id()",
    meta: _91id_93MIpt5Nkr4IMeta || {},
    component: () => import("/home/forge/www.birlikcati.com.tr/pages/account/statement/detail/[id].vue")
  },
  {
    name: "account-statement___tr",
    path: "/account/statement",
    meta: indexHwimpcAXL0Meta || {},
    component: () => import("/home/forge/www.birlikcati.com.tr/pages/account/statement/index.vue")
  },
  {
    name: "account-user___tr",
    path: "/account/user",
    meta: user1dvsqPX6JBMeta || {},
    component: () => import("/home/forge/www.birlikcati.com.tr/pages/account/user.vue")
  },
  {
    name: "account-wishlist___tr",
    path: "/account/wishlist",
    meta: wishlistc9smznb6t5Meta || {},
    component: () => import("/home/forge/www.birlikcati.com.tr/pages/account/wishlist.vue")
  },
  {
    name: "cart___tr",
    path: "/cart",
    component: () => import("/home/forge/www.birlikcati.com.tr/pages/cart.vue")
  },
  {
    name: "checkout___tr",
    path: "/checkout",
    meta: indexuA2pqLtfjdMeta || {},
    component: () => import("/home/forge/www.birlikcati.com.tr/pages/checkout/index.vue")
  },
  {
    name: "checkout-payment___tr",
    path: "/checkout/payment",
    meta: paymentrZ4lNH4HNEMeta || {},
    component: () => import("/home/forge/www.birlikcati.com.tr/pages/checkout/payment.vue")
  },
  {
    name: "checkout-summary___tr",
    path: "/checkout/summary",
    component: () => createClientPage(() => import("/home/forge/www.birlikcati.com.tr/pages/checkout/summary.client.vue"))
  },
  {
    name: "customer-request___tr",
    path: "/customer/request",
    component: () => import("/home/forge/www.birlikcati.com.tr/pages/customer/request.vue")
  },
  {
    name: "customer-success___tr",
    path: "/customer/success",
    component: () => import("/home/forge/www.birlikcati.com.tr/pages/customer/success.vue")
  },
  {
    name: "iletisim___tr",
    path: "/iletisim",
    component: () => import("/home/forge/www.birlikcati.com.tr/pages/iletisim.vue")
  },
  {
    name: "index___tr",
    path: "/",
    meta: indexrAxK1zYQc0Meta || {},
    component: () => import("/home/forge/www.birlikcati.com.tr/pages/index.vue")
  },
  {
    name: "login___tr",
    path: "/login",
    meta: loginWkZxO6fqg6Meta || {},
    component: () => import("/home/forge/www.birlikcati.com.tr/pages/login.vue")
  },
  {
    name: "offer___tr",
    path: "/offer",
    meta: offeru9fWw9MAUVMeta || {},
    component: () => import("/home/forge/www.birlikcati.com.tr/pages/offer.vue")
  },
  {
    name: "order-status___tr",
    path: "/order/status",
    component: () => import("/home/forge/www.birlikcati.com.tr/pages/order/status.vue")
  },
  {
    name: "order-track___tr",
    path: "/order/track",
    component: () => import("/home/forge/www.birlikcati.com.tr/pages/order/track.vue")
  },
  {
    name: "password-forgot___tr",
    path: "/password/forgot",
    meta: forgotmiWz4fcTugMeta || {},
    component: () => import("/home/forge/www.birlikcati.com.tr/pages/password/forgot.vue")
  },
  {
    name: "password-reset___tr",
    path: "/password/reset",
    meta: resetMlDaKn1VLAMeta || {},
    component: () => import("/home/forge/www.birlikcati.com.tr/pages/password/reset.vue")
  },
  {
    name: "payment-notification___tr",
    path: "/payment-notification",
    component: () => import("/home/forge/www.birlikcati.com.tr/pages/payment-notification.vue")
  },
  {
    name: "payment-summary___tr",
    path: "/payment/summary",
    component: () => import("/home/forge/www.birlikcati.com.tr/pages/payment/summary.vue")
  },
  {
    name: "register___tr",
    path: "/register",
    meta: registerTvU9Wkdp4DMeta || {},
    component: () => import("/home/forge/www.birlikcati.com.tr/pages/register.vue")
  },
  {
    name: "search___tr",
    path: "/search",
    meta: searchknXsk20peWMeta || {},
    component: () => import("/home/forge/www.birlikcati.com.tr/pages/search.vue")
  },
  {
    name: "contact___tr",
    path: "/magazalarimiz",
    component: () => import("/home/forge/www.birlikcati.com.tr/modules/locationsPage/pages/locations.vue")
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/weber-dry-fdf-plus",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/weber_br_89",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/atiskan_br_91",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/ince-cati_br_106",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/Arcelik_br_14",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/knauf_br_90",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/decostar_br_104",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/cukurova-yalitim_br_83",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/Alarko_br_48",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/rainbow_br_152",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/osmanli_br_98",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/btm_br_84",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/firat_br_87",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/kronospan_br_99",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/ytong_br_82",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/tepe_br_92",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/cinar-boya_br_79",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/magmaweld_br_105",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/tiger_br_109",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/atermit_br_86",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/thermex_br_100",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/duramit_br_156",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/ondumit_br_95",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/gasfe_br_155",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/esco-teknik_br_144",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/jardin_br_146",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/tezcan_br_81",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/american-siding_br_97",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/mesa-teknik_br_110",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/akfix_br_142",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/euromaag_br_108",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/gurcam-plastik_br_129",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/defne_br_153",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/qis_br_107",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/smart_br_149",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/filex_br_145",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/dekpax_br_150",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/weber-a1-yapistirici-mantolama-yapistirici",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/ondumit-levha-pvc-kiremit-fiyati",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/weber-floor-ys-4-gri-yuzey-sertlestirici-_10587.html",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/havalandirma-suzgec",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/ode-r-flex-folyolu-kaucuk-levha-9-mm",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/agraf-alcipan-yardimci-malzeme-alcipan-aksesuar-12",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/weber-silikon-banyo-antibakteriyel-kararmayan-dusakabin-silikonu",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/oluk-kelepce-firat",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/knauf-alcipan-derz-bandi-fiyati",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/trapez-cati-vida-3,20-mm",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/trapez-sac-mahyasi-bursa-omurga",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/content32/mesafeli-satis-sozlesmesi",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/neotherm-karbonlu-eps",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/polikarbon-solid-levha",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/weber-urunleri-10121",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/oluk-dekoratif-bursa",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/bitumenflex-1k-zift-5-kg",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/askiteli-aski-cubugu-100cm",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/seramik-yapistirici-ve-derz-dolgular-10143",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/weber-dress-b-flex-boardex-levha-sivasi",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/siding-h-kanal-profili-mavi_11036.html",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/kose-kapama-duvar-dibi",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/saten-alci-atiskan-bursa",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/vida-sivri-uclu-alcipan-vidasi-35mm",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/surteks-kiremit-alti-cuvalli-zift",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/pah-bandi-fiyati",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/ode-3-mm-membran-su-yalitim-p-300-bursa-bayi",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/weber-floor-lvl-combi-alci-esasli-akilli-sap",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/upim-aski-pimi-civi-6cm",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/atiskan-siva-alci-fiyati",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/weber-dry-rapid-stop-yildirim-tozu",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/weber-dry-ss-7-su-yalitim-harci",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/tasyunu-rabitz-telli-silte-5-cm-tip-650_10526.html",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/ctp-trapez-oluklu-seffaf-aydinlik",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/shingle-civi-bursa",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/alcipan-mudahale-kapagi-40-40",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/polikarbon-levha-seffaf-aydinlatma-10-mm",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/atermit-mahya",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/duvaru-alcipan-bolme-duvar-galvaniz-50mm-bursa",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/tasyunu-cati-paneli-5-cm",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/weber-foam-poliuretan-dolgu-kopugu",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/weber-latex-20-kg_10582.html",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/firat-boru-dirsek-50x87_10413.html",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/dikis-vida-25-mm",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/weber-floor-lvl-100-multi-zemin-tesviye-sapi",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/duramit-pvc-plastik-kiremit-kirmizi-263-cm_11287.html",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/alci-ve-alcipan-urunleri-10127",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/alfa-ap-300-membran",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/izocam-karbonlu-eps-4-cm-6-mc2b2-_10518.html",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/sinus-oluklu-sac-",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/alcipan-mudahale-kapagi-50-50",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/weber-dry-ss-10-cimento-esasli-su-yalitimi",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/ondumit-pormit-tepe-mahya",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/weber-20-extra-su-gecirimsizlik-harc-katkisi",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/klips-alcipan-aksesuari",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/agraf-alcipan-yardimci-malzeme-aksesuar-15cm",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/weber-kol-flex-granit-elastik-seramik-yapistirici",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/weber-fermafix-seramik-yapistirici-beyaz",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/weber-rep-m-352-tamir-harci",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/su-yalitim-file-50-gr",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/siding-genis-dis-kose-profili",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/shingle-4*4-petek-cati-kaplama-bursa",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/yaprak-tutucu-ahtapot-suzgec",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/trapez-sac-semeri",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/atermit-bursa-bayi",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/_br_61",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/sumaks-kiremit-alti-nem-bariyeri-cuvalli",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/ode-rflex-kaucuk-levha-19-mm-fiyati",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/beton-ve-harc-katki-malzemeleri-10148",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/weber-swell-tape-sisen-bant",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/vida-sivri-uclu-alcipan-vidasi-25mm",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/shingle-10137",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/trapez-cati-vida-105-mm",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/weber-dry-ss-7-easy_10384.html",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/4*4-shingle-safir-yesil",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/izocam-karbonlu-xps",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/ode-luna-max-p-300-membran-bursa",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/elektrod-oerlikon",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/ode-menbran-grubu-10178",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/cati-cikis-kapaklari/cati-cikis-kapak-kiremit-shingle-bursa",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/cati-cikis-kapaklari/cati-cikis-kapagi-shingle-camli-kapak-bursa",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/agraf-alcipan-yardimci-malzeme-alcipan-aksesuar-20",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/su-tutucu-bant-yalitim-bandi",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/weber-superflex-a-200-dilatasyon-bandi",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/weber-ankraj-pe-kimyasal-kartus",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/alcipan-mudahale-kapagi-bursa",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/trapez-alin-kapama-trapez-alti-kapama",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/weber-repad-korozyon-onleyici-astar-harc",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/polimit-cati-levha-ust-mahya",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/weber-pu-2a-poliuretan-mastik-beyaz-280-ml_10701.html",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/izocam-peflex-polietilen-levha-parke-siltesi-10mm",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/ode-arduaz-pp-40-membran-kumlu-yesil-bursa-bayi",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/firat-pvc-catal-50*100",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/weber-pu-2a-poliuretan-mastik-np1-sosis-silikon",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/desenli-alin-oluk-gizleme",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/ode-rflex-kaucuk-folyolu-13-mm-levha",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/betopan-16-mm_10626.html",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/weber-latex-5-kg",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/semer-pul-beyaz",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/cati-paneli-10139",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/weber-ep-easy-epoksi-derz-dolgu",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/firat-pvc-boru-catal",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/firat-acik-dirsek-pvc-70-45",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/kendinden-yapiskanli-bantlar-10149",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/seramik-yunu-25-mm-96-yogunluk-4-392-mc2b2-_11306.html",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/oluk-dirsek-firat-125",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/ode-isi-yalitim-urunleri-10176",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/trapez-sac-duvar-dibi",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/sandwic-panel-duvar-dibi",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/cephe-paneli-cephe-kaplama",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/ode-r-flex-kaucuk-folyolu-boru-izolasyonu",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/ode-starflex-camyunu-boru-27-mm",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/Iletisim",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/weber-yapistirici-mantolama-harci-yp-bursa",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/weber-emprenye-5-lt",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/american-siding-10156",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/yapi-malzemeleri-10129",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/weber-prim-max-akrilik-zemin-astari",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/agraf-30-cm",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/firat-kapali-dirsek-pvc-70-87",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/weber-prim-tg5-seramik-ustu-astar-tutkal",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/weber-rep-grout_10589.html",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/tiner",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/firat-acik-dirsek-pvc-50-45",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/upim-aski-pimi-8-cm",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/seffaf-oluklu-levhalar-10140",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/weber-therm-eps-plus-4-cm-",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/weber-joint-hr-flex-derz-dolgu",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/siding-sacak-alin-profili",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/weber-dry-fdf-plus-akrilik-recine-esasli-su-yalitim-likit",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/weber-dry-fdf-plus-beyaz-20kg_10420.html",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/ondumit-polimer-cati-paneli-10136",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/desenli-kose-alin-kapama-bursa",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/decostar-dekoratif-tane-dokulu-siva-fiyati",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/izocam-cati-siltesi-camyunu-folyolu-10cm",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/firat-pvc-oluk-malzemeleri",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/izokap-zift-surme-izolasyon-bitum-kaucuklu-zift",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/izocam-peflex-polietilen-levha-parke-siltesi-3mm",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/decostar-mantolama-yapistirici",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/plastik-lambiri-ve-aksesuarlari-10126",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/teknik-malzemeler-10141",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/cati-cikis-kapak-trapez-form-bursa",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/alcipan-asma-tavan-knauf-atiskan-bursa",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/cati-aksesuarlari-ve-yardimci-malz-10134",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/weber-dry-ss-8-extra-su-yalitim-harci",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/polikarbon-levha-seffaf-aydinlatma-4-mm",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/ode-2-mm-membran-su-yalitimi-c-200-bursa",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/corrubit-btm-oluklu-levha-onduline-bursa",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/gazbeton-ytong-urunleri-bursa",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/su-tutucu-bant-yalitim-bandi?tag=su tutucu",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/alcipan-ekleme-parcasi-bursa",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/weber-dry-uv-coat-teras-su-yalitim-elastik",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/xps-mantolama-10167",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/atiskan-dekoratif-siva-bursa",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/focus-bonus-mebran",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/betopan-beton-plaka-tepe-8-mm",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/izoser-zift-bitum",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/atermit-levha-beton-cati-bursa-250-cm",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/yangina-dayanikli-alcipan-pembe-knauf-lafarge-atiskan",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/havalandirma-malzemeleri-10169",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/mantolama-dubeli-plastik-dubel",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/karbosan-kesici-tas-230-luk",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/betopan-beton-plaka-tepe-10-mm",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/weber-rep-grout-rapid-bursa",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/ode-starflex-camyunu-boru-89-mm",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/weber-mantoloma-urunleri-10146",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/weber-dry-uv-coat-_10380.html",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/asma-tavan-malzemeleri-10163",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/osb-dubeli-plastik-dubel",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/ondumit-yesil-plastik-kiremit-levha",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/weber-floor-ys6-yuzey-sertlestirici",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/kartonpiyer-alci-atiskan-bursa",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/weber-pu-2a-poliuretan-mastik-np1",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/firat-acik-dirsek-100-45",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/alcipan-10161",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/montaj-kopugu-mantolama",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/ode-isipan-xps-4-cm-duz",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/cati-cikis-kapagi-shingle-camli-kapak-bursa",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/onduline-civi-aksesuar",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/sinus-oluklu-seffaf",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/ode-r-flex-kaucuk-folyolu-boru-bursa",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/weber-rep-hks-bursa",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/10-cm-parmak-rulo",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/izocam-cati-siltesi-camyunu-8-cm",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/cati-kaplama/polimit-polimer-cati-paneli-bursa",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/yardimci-malzemeler-insaat-bursa/alcipan-montaj-vidalari-civiler-bursa",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/3-919-matkap-uclu-lambiri-vidasi_11154.html",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/kiremit-alti-su-yalitim-nem-bariyeri-bursa",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/kirmizi-antipas-boya",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/izopan-izocam-soba-panosu-petek-arkasi",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/weber-rep-ad_10365.html",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/weber-kol-rapid-hizli-priz-alan-seramik-yapistirici",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/su-yalitimi-urunleri-10142",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/poliuretan-kopuk-montaj-kopugu",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/weber-dry-ultraflex-t-25-tam-elastik-su-yalitimi",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/derz-dolgu-fitil-polietilen-fitili-bursa",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/ondumit-yesil-300-cm_10916.html",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/dirsek-5045_10412.html",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/weber-tec-905-solventli-bitum-likit-membran-bursa-bayi",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/astar-zift-membran-astari-bitum",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/Gazbeton-Ytong-20-cm-Tugla-bursa",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/firat-oluk-inisi",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/weber-tasyunu-mantolama-isi-yalitimi-5cm",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/polikarbon-levha-seffaf-aydinlatma-fume",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/osmanli-izolastik-18-lt_10499.html",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/cati-cikis-kapagi-panel-camli-kapak-bursa",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/duvar-u-100-profili_10908.html",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/trapez-vida-75-mm",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/4*4-shingle-bursa",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/weber-tec-rk-tamir-harci",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/lafarge-boardex-alcipan/boardex-levha-dis-cephe-levhasi-bursa",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/sandwic-panel-mahya-bursa",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/weber-ep-800-epoksi-yapistirici-derz-dolgu",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/weber-kol-src-max-seramik-yapistirma-harci-seracim",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/firat-oluk-kelepce",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/thermex-karbonlu-eps-3-cm_10686.html",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/tasyunu-tavan-kosebent",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/cati-cikis-kapagi-tekkat-panel-beyaz-camli-_11260.html",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/osb-ahsap-levha-15mm",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/duvarc-alcipan-bolme-duvar-galvaniz-profil-50mm-bursa",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/osb-levha-10135",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/weber-dry-2k-crs-surme-su-yalitimi",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/yardimci-malzemeler-10174",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/weber-kol-flex-extra-gri-25-kg-c2-te-s2-_11029.html",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/ondumit-polimit-pvc-cati-levha",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/cati-kaplama/osb-levha",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/ode-arduaz-pp-40-membran-kumlu-kirmizi-bursa",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/teknik-malzemeler",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/weber-superflexd2-su-yalitim-esnek-elastik",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/btm-shingle-galaksi-modern-yesil-petek",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/plastik-tavan-lambiri-beyaz-bursa",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/boardex-10159",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/izocam-cati-siltesi-camyunu-folyolu-8-cm",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/firat-dirsek-boru-pvc-50",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/Gazbeton-Ytong-Tugla-5cm-bursa",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/izocam-karbonlu-eps-isi-yalitimi-bursa",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/weber-bayi-repma-200-yapisal-tamir-harci",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/weber-kur-a-20-lt",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/betopan-16-mm-bursa",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/duvaru-alcipan-bolme-duvar-galvaniz-75mm-bursa",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/oluk-manson-ekleme-firat",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/seramik-yapistirici-derz-dolgu-bursa/weber-kol-srk",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/american-siding-bursa/siding-j-kanali",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/american-siding-bursa/siding-genis-alinli-söve",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/ondumit-cati-levha-birlesim",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/weber-foam-poliuretan-kopuk",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/alcipan-klips_10528.html",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/trapez-vida-60-mm",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/ode-nfaf-yapiskanli-tb50-40-10-yumurta-100100-cm-10-m2-pkt-_10708.html",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/ondumit-semeri_10660.html",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/izocam-foamboard-isi-yalitim-xps",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/ode-r-flex-kaucuk-folyolu-boru-fiyati",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/sandvic-panel-vidasi-fiyati",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/geotekstil-kece-200-gr_10510.html",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/siding-h-kanali-profili",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/izocam-manto-tasyunu-120-yog-5-cm-2-16-m2-_10346.html",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/ode-nfaf-ses-yalitim-sungeri",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/izocam-tasyunu-ses-yalitimi-isi-5cm",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/oluk-inis-firat",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/pvc-denizlik-uzatma-profili-5-5-cm_10470.html",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/r/kose-kaplama-1010-bordo_10670.html",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/askimasasi-alcipan-aski-masasi",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/content30/iade-ve-degisim",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/cephe-kaplama-10157",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/delikli-kose-profili-kosebent-alci-aksesuar-delikli-kosebent",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/izolastik-zift-surme-izolasyon-bitum-kaucuklu-zift",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/drenaj-levhasi-fondolin",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/firat-boru-pvc-dublex-50lik",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/firat-yagmur-olugu-150-lik_10448.html",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/membranlar-10147",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/weber-floor-lvl-10-premium-aklli-sap-25-kg",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/betopan-18-mm-bursa",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/Gazbeton-Ytong-10cm-Tugla",
    component: component_45stubMEdeFjB9DO
  },
  {
    name: component_45stubMEdeFjB9DOMeta?.name,
    path: "/su-ile-sisen-sizdirmazlik-bandi_10989.html",
    component: component_45stubMEdeFjB9DO
  }
]