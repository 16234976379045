import validate from "/home/forge/www.birlikcati.com.tr/node_modules/nuxt/dist/pages/runtime/validate.js";
import app_45global from "/home/forge/www.birlikcati.com.tr/middleware/app.global.js";
import manifest_45route_45rule from "/home/forge/www.birlikcati.com.tr/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  app_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  guest: () => import("/home/forge/www.birlikcati.com.tr/middleware/guest.js"),
  protected: () => import("/home/forge/www.birlikcati.com.tr/middleware/protected.js"),
  restrict: () => import("/home/forge/www.birlikcati.com.tr/middleware/restrict.js"),
  wholesale: () => import("/home/forge/www.birlikcati.com.tr/middleware/wholesale.js"),
  auth: () => import("/home/forge/www.birlikcati.com.tr/node_modules/@sidebase/nuxt-auth/dist/runtime/middleware/auth.js")
}